import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import { setModalState } from '../../../store/redux-slices/login'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'react-bootstrap'

const LogoutModal = ({ isOpen, handleLogout }) => {
  const { logoutLoading } = useSelector(state => state.login)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const handleCloseModal = () => {
    dispatch(setModalState({ key: 'isLogoutOpen', value: false }))
  }
  return (
    <Modal id='logoutmodal' show={isOpen} onHide={handleCloseModal} centered>
      <Modal.Header closeButton className='d-flex justify-content-center'>
        <Modal.Title className='w-100 text-center'>{t('logout')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='  logout-text'>
          {t('logoutContent')}
        </p>

        <button type='button' disabled={logoutLoading} data-bs-dismiss='modal' aria-label='Close' onClick={() => handleLogout()} className='logout-btn w-100 justify-content-center '>{t('logout')} {logoutLoading && <Spinner className='ms-2' size='sm' />}  </button>
      </Modal.Body>
    </Modal>

  )
}

export default LogoutModal
