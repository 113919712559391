import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useWalletAction from './hooks/useWalletAction'
import VerifyPasswordModal from '../modals/auth/transactions/VerifyPasswordModal'
import { Form, Formik } from 'formik'
import { transactionValiation } from './schema'
import { WALLET_ACTION } from '../../utils/constants'
import Button from '../form-elements/Button/Button'
import SelectField from '../form-elements/SelectField/SelectField'
import TextField from '../form-elements/TextField/TextField'
const initialVal = {
  amount: '',
  currency: ''
}
const DepositWithdraw = () => {
  const { t } = useTranslation()
  const {
    showAccNumber,
    setShowAccNumber,
    verifyUserLoading,
    isPasswordModalOpen,
    setIsPasswordModalOpen,
    handleWalletAction,
    handleSubmit,
    data,
    walletActionLoading,
    actionType,
    setActionType,
    userDetails,
    setIsToViewAccNumber,
    setData,
    searchParams
  } = useWalletAction()
  const [form, setForm] = useState(initialVal)
  useEffect(() => {
    setForm(initialVal)
  }, [actionType])

  const handlePasswordVisibility = () => {
    if (showAccNumber) {
      setShowAccNumber(prev => !prev)
      setIsToViewAccNumber(false)
    } else {
      setIsToViewAccNumber(true)
      setData({ actionSource: WALLET_ACTION.DEPOSIT })
      setIsPasswordModalOpen(true)
    }
  }

  const renderForm = (type) => {
    const last4Digits = userDetails?.adminDetail?.adminAccountNumber?.slice(-4)
    const maskedNumber = last4Digits?.padStart(userDetails?.adminDetail?.adminAccountNumber?.length, '*')
    return (
      <div className='deposit-withdraw'>
        <Formik
          initialValues={form}
          validationSchema={transactionValiation(t, actionType, userDetails?.userWallet?.amount)}
          onSubmit={(values) => handleWalletAction(type, values)}
          validateOnChange
          validateOnBlur
          enableReinitialize
          key={type}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            setFieldTouched
          }) => {
            return (
              <Form className=' signup-form1 d-flex flex-column gap-3'>
                <div className=' deposit-withdraw--parent  '>
                  <div className='d-flex gap-2 align-items-center acc-number'>
                    <div>
                      {t('accountNumberLabel')}:
                    </div>
                    <div>
                      {showAccNumber
                        ? userDetails?.adminDetail?.adminAccountNumber
                        : maskedNumber}
                    </div>
                    <Button variant='primary' title={showAccNumber ? t('hide') : t('show')} type='button' onClick={handlePasswordVisibility} className='ms-auto neo-active' />
                  </div>
                  <div className=' d-flex  flex-column input-label'>

                    <div className='form-group'>

                      <SelectField
                        label={t('currency')}
                        name='currency'
                        value={values.currency}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        firstOption={
                          <option value='' disabled>
                            {t('selectCurrency')}
                          </option>
                        }
                        options={[
                          { label: 'KRW', value: 'KRW' }
                        ]}
                      />
                    </div>
                  </div>
                  <div>

                    <TextField
                      isCurrencyField
                      label={t('amount')}
                      name='amount'
                      value={values.amount}
                      onChange={(val) => {
                        setFieldValue('amount', val)
                      }}
                      onBlur={handleBlur}
                      placeholder={t('enterAmountPlaceholder')}
                    />

                  </div>
                </div>
                <div className='footer-modal'>
                  <ul>

                    <li>
                      <Button variant='primary' loading={walletActionLoading} title={walletActionLoading ? t('loading') : type === WALLET_ACTION.WITHDRAW ? t('requestWithdraw') : t('requestDeposit')} type='submit' />

                    </li>
                  </ul>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    )
  }
  const memoiZedForm = useMemo(() => renderForm(actionType), [actionType, showAccNumber, walletActionLoading, t])
  return (
    <>

      <div>
        <ul className='nav nav-pills d-flex  m-auto list-unstyled ' id='pills-tab' role='tablist'>
          <li className='nav-item' role='presentation'>
            <button disabled={searchParams.get('type') === 'withdraw'} onClick={() => setActionType(WALLET_ACTION.DEPOSIT)} className={`nav-link ${actionType === WALLET_ACTION.DEPOSIT && 'active'}`} id='pills-deposit-tab' data-bs-toggle='pill' data-bs-target='#pills-deposit' type='button' role='tab' aria-controls='pills-deposit' aria-selected='true'>{t('depositWallet')}</button>
          </li>
          <li className='nav-item' role='presentation'>
            <button disabled={searchParams.get('type') === 'deposit'} onClick={() => setActionType(WALLET_ACTION.WITHDRAW)} className={`nav-link ${actionType === WALLET_ACTION.WITHDRAW && 'active'}`} id='pills-withdraw-tab' data-bs-toggle='pill' data-bs-target='#pills-withdraw' type='button' role='tab' aria-controls='pills-withdraw' aria-selected='false'>{t('withdrawalWallet')}</button>
          </li>
        </ul>
        <div className='tab-content' id='pills-tabContent'>
          <div className='tab-pane fade show active' id='pills-deposit' role='tabpanel' aria-labelledby='pills-deposit-tab'>
            {memoiZedForm}
          </div>
          <div className='tab-pane fade' id='pills-withdraw' role='tabpanel' aria-labelledby='pills-withdraw-tab'>
            {memoiZedForm}
          </div>
        </div>
      </div>
      <VerifyPasswordModal
        type={data?.actionSource}
        isOpen={isPasswordModalOpen}
        handleClose={() => setIsPasswordModalOpen(false)}
        onSubmit={handleSubmit}
        loading={verifyUserLoading}
      />
    </>
  )
}

export default DepositWithdraw
