import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { getItem } from '../../utils/storageUtils'
import ENJSON from './EN'
import KOJSON from './KO'
import JAJSON from './JA'
import { initialLangCode } from '../../utils/constants'

// the translations
const resources = {
  RU: { ...ENJSON },
  PS: { ...ENJSON },
  AR: { ...ENJSON },
  EN: { ...ENJSON },
  PT: { ...ENJSON },
  DU: { ...ENJSON },
  ES: { ...ENJSON },
  SR: { ...ENJSON },
  BS: { ...ENJSON },
  BG: { ...ENJSON },
  CN: { ...ENJSON },
  JA: { ...JAJSON },
  MS: { ...ENJSON },
  GR: { ...ENJSON },
  TR: { ...ENJSON },
  CZ: { ...ENJSON },
  SK: { ...ENJSON },
  DA: { ...ENJSON },
  HI: { ...ENJSON },
  SW: { ...ENJSON },
  HU: { ...ENJSON },
  ID: { ...ENJSON },
  FA: { ...ENJSON },
  IT: { ...ENJSON },
  KO: { ...KOJSON },
  MT: { ...ENJSON },
  MU: { ...ENJSON },
  RO: { ...ENJSON },
  TH: { ...ENJSON },
  VI: { ...ENJSON },
  PL: { ...ENJSON }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getItem('language')?.toUpperCase() || initialLangCode,
    keySeparator: false, // we do not use keys in form (messages.welcome)
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
