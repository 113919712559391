import { format, isToday, isTomorrow } from 'date-fns'

export const formatDateMDY = (date) => {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [month, day, year].join('-')
}

export const formatDateDMY = (date) => {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [day, month, year].join('-')
}

export const formatDateYMD = (date) => {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

export const getDateTime = (dateTime) => {
  const d = new Date(dateTime)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()
  let hours = d.getHours()
  let minutes = d.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours || 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  const time = hours + ':' + minutes + ' ' + ampm

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  const formatedDateTime = `${month}/${day}/${year} - ${time}`

  return formatedDateTime
}

export const getFullDateTime = (dateTime) => {
  const d = new Date(dateTime)
  const month = d.toLocaleString('default', { month: 'long' })
  let day = '' + d.getDate()
  const year = d.getFullYear()
  let hours = d.getHours()
  let minutes = d.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours || 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  const time = hours + ':' + minutes + ' ' + ampm

  if (day === 1 || day === '1' || day === '01') {
    day += 'st'
  } else if (day === 2 || day === '2' || day === '02') {
    day += 'nd'
  } else if (day === 3 || day === '3' || day === '03') {
    day += 'rd'
  } else {
    day += 'th'
  }

  const formatedDateTime = `${day} ${month} ${year} - ${time}`
  return formatedDateTime
}

export const getDateDaysAgo = (days) => {
  const now = new Date()
  now.setDate(now.getDate() - days)
  return now
}

export const getDateDaysAfter = (days) => {
  const now = new Date()
  now.setDate(now.getDate() + days)
  return now
}

export const formatDate = (inputDate, t) => {
  const date = new Date(inputDate)
  if (!isNaN(date.getTime())) {
    const formattedTime = format(date, 'hh:mm a')
    if (isToday(date)) {
      if (t) {
        return `${t('today')} ${formattedTime}`
      } else {
        return `Today ${formattedTime}`
      }
    } else if (isTomorrow(date)) {
      if (t) {
        return `${t('tomorrow')} ${formattedTime}`
      } else {
        return `Tommorow ${formattedTime}`
      }
    }
    const formattedDate = format(date, 'MMM dd, HH:mm')
    return formattedDate
  } else {
    return inputDate
  }
}

export const calculateHourTime = (timeDiff) => {
  const to = new Date()
  to.setHours(to.getHours() + timeDiff)
  return { from: new Date(), to: to }
}

export const formatDateMDYWithTime = (fromDate, toDate) => {
  // const todayStart = new Date(new Date(fromDate).setHours(0, 0, 0, 0))
  const todayEnd = new Date(new Date(toDate).setHours(23, 59, 59, 999))
  return {
    from: fromDate,
    to: todayEnd
  }
}
