import { takeLatest, put } from 'redux-saga/effects'

import {
  getCmsPage,
  getTenantBannerList,
  getTenantCmsFooter,
  getTenantConfigurations
} from '../../utils/apiCalls'

import {
  getTenantsConfigurationStart,
  getTenantsConfigurationSuccess,
  getTenantsConfigurationFailure,
  getCMSPageStart,
  getCMSPageSuccess,
  getCMSPageFailure,
  getTenantsBannersStart,
  getTenantsBannersSuccess,
  getTenantsBannersFailure,
  getFooterCmsStart,
  getFooterCmsSuccess,
  getFooterCmsFailure
} from '../redux-slices/cms'

export default function * tenantsConfigurationWatcher () {
  yield takeLatest(getTenantsConfigurationStart.type, getTenantsConfigurationWorker)
  yield takeLatest(getCMSPageStart.type, getCMSPageWorker)
  yield takeLatest(getTenantsBannersStart.type, getTenantBannerWorker)
  yield takeLatest(getFooterCmsStart.type, getFooterCmsWorker)
}

function * getFooterCmsWorker (action) {
  try {
    const { data } = yield getTenantCmsFooter()
    yield put(getFooterCmsSuccess(data?.data))
  } catch (e) {
    yield put(getFooterCmsFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getTenantsConfigurationWorker (action) {
  try {
    const { data } = yield getTenantConfigurations()
    yield put(getTenantsConfigurationSuccess(data?.data))
  } catch (e) {
    yield put(getTenantsConfigurationFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getCMSPageWorker (action) {
  const { cmsPageId } = action && action.payload
  try {
    const { data } = yield getCmsPage({ cmsPageId })
    yield put(getCMSPageSuccess(data?.data?.cmsDetails))
  } catch (e) {
    yield put(getCMSPageFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getTenantBannerWorker () {
  try {
    const { data } = yield getTenantBannerList()
    yield put(getTenantsBannersSuccess(data?.data?.bannerList?.bannerUrl))
  } catch (e) {
    yield put(getTenantsBannersFailure(e?.response?.data?.errors[0]?.description))
  }
}
