import { createSlice } from '@reduxjs/toolkit'

const casinoSlice = createSlice({
  name: 'casinoMenus',
  initialState: {
    loading: false,
    homeLoading: false,
    gameCategoryLoading: false,
    gameCategory: {},
    gameSubCategory: {},
    games: {},
    mappedCategory: {},
    playGame: {},
    homeCasinoTopGames: {},
    homeCasinoTopGamesLoading: false,
    providers: [],
    providersLoading: false,
    favoriteGames: {},
    favoriteGamesList: {},
    filteredGames: null,
    filteredLoading: false,
    favLoading: false,
    subCatLoading: false,
    rowsFetchedCount: 0,
    categoryProviderList: null,
    providerListLoading: false,
    recommendedGames: {},
    recommendedLoading: false,
    homeCasinoTopGamesUpdateLoading: false
  },
  reducers: {
    setMappedCategory: (state, { payload }) => ({
      ...state,
      mappedCategory: payload
    }),
    updateHomeGameDataStart: (state, { payload }) => ({
      ...state,
      homeCasinoTopGamesUpdateLoading: true

    }),
    updateHomeGameDataComplete: (state, { payload }) => ({
      ...state,
      homeCasinoTopGames: {
        ...state.homeCasinoTopGames,
        [payload.id]: payload.data
      },
      homeCasinoTopGamesUpdateLoading: false
    }),
    updateHomeGameDataFailure: (state, { payload }) => ({
      ...state,
      homeCasinoTopGamesUpdateLoading: false
    }),
    getRecommendedGamesStart: (state) => ({
      ...state,
      recommendedLoading: true
    }),
    getRecommendedGamesSuccess: (state, { payload }) => ({
      ...state,
      recommendedLoading: false,
      recommendedGames: payload
    }),
    getRecommendedGamesFailure: (state) => ({
      ...state,
      recommendedLoading: false
    }),
    getGameCategoryStart: (state) => ({
      ...state,
      gameCategoryLoading: true
    }),
    getGameCategorySuccess: (state, { payload }) => ({
      ...state,
      gameCategoryLoading: false,
      gameCategory: payload
    }),
    getGameCategoryFailure: (state) => ({
      ...state,
      gameCategoryLoading: false
    }),
    getPlayGameStart: (state) => ({
      ...state,
      loading: true
    }),
    getPlayGameSuccess: (state, { payload }) => {
      return ({
        ...state,
        loading: false,
        playGame: payload
      })
    },
    updateGames: (state, { payload }) => {
      return ({
        ...state,
        games: { ...state.games, casinoGameListing: payload }
      })
    },
    getPlayGameFailure: (state) => ({
      ...state,
      loading: false,
      playGame: {}
    }),
    resetPlayGameData: (state) => ({
      ...state,
      loading: false,
      playGame: {}
    }),
    getTopSlotsGamesStart: (state) => ({
      ...state,
      homeCasinoTopGamesLoading: true
    }),
    getTopSlotsGamesSuccess: (state, { payload }) =>
      ({
        ...state,
        homeCasinoTopGamesLoading: false,
        homeCasinoTopGames: payload
      }),
    updateTopSlotsGames: (state, { payload }) =>
      ({
        ...state,
        homeCasinoTopGames: payload
      }),
    getTopSlotsGamesFailure: (state) => ({
      ...state,
      homeCasinoTopGamesLoading: false
    }),
    getGameSubCategoryStart: (state) => ({
      ...state,
      subCatLoading: true
    }),
    getGameSubCategorySuccess: (state, { payload }) => ({
      ...state,
      subCatLoading: false,
      gameSubCategory: payload.subCategories,
      favoriteGames: payload.favoriteGames || {}
    }),
    getGameSubCategoryFailure: (state) => ({
      ...state,
      subCatLoading: false
    }),
    getGameStart: (state) => ({
      ...state,
      loading: true
    }),
    getHotGameStart: (state) => ({
      ...state,
      loading: true
    }),
    getNewGameStart: (state) => ({
      ...state,
      loading: true
    }),
    getGameSuccess: (state, { payload }) => {
      const {
        casinoGameListing = [], pageInfo = {
          currentPage: 1,
          totalPages: 1,
          totalItems: 0
        }, providerData
      } = payload

      let games = {}
      const { currentPage, totalPages } = pageInfo && pageInfo
      const isAlreadyExist = state.games?.pageInfo?.currentPage > 0

      if (Number(currentPage) === 1) {
        games = {
          casinoGameListing,
          pageInfo: {
            ...pageInfo,
            totalPages: pageInfo.totalPages === 0 ? 1 : pageInfo.totalPages
          },
          providerData: isAlreadyExist ? state.games?.providerData : providerData
        }
      } else {
        if (Number(currentPage) > Number(state.games.pageInfo.currentPage)) {
          games = {
            ...state.games,
            casinoGameListing: [...state.games.casinoGameListing, ...casinoGameListing],
            pageInfo: {
              ...state.games.pageInfo,
              currentPage,
              totalPages
            }

          }
        } else {
          games = { ...state.games }
        }
      }

      return ({
        ...state,
        loading: false,
        games: games
      })
    },
    getGameFailure: (state) => ({
      ...state,
      loading: false
    }),
    resetGames: (state) => ({
      ...state,
      games: {}
    }),
    resetCasinoData: (state) => ({
      ...state,
      games: {}
    }),
    getFavGameStart: (state) => ({
      ...state,
      favLoading: true
    }),
    getFavGameSuccess: (state, { payload }) => {
      const {
        favoritesGames = [], pageInfo = {
          currentPage: 1, totalPages: 1
        }, isToggle
      } = payload
      let favGames = {}
      const { currentPage, totalPages } = pageInfo

      if (isToggle || Number(currentPage) === 1) {
        favGames = { favoritesGames, pageInfo }
      } else {
        if (Number(currentPage) > Number(state.favoriteGamesList.pageInfo.currentPage)) {
          favGames = {
            favoritesGames: [...state.favoriteGamesList.favoritesGames, ...favoritesGames],
            pageInfo: {
              ...state.favoriteGamesList.pageInfo,
              currentPage,
              totalPages
            }

          }
        } else {
          favGames = { ...state.favoriteGamesList }
        }
      }

      return ({
        ...state,
        favLoading: false,
        favoriteGamesList: favGames
      })
    },
    getFavGameFailure: (state) => ({
      ...state,
      favLoading: false
    }),
    getHomeDataStart: (state) => ({
      ...state,
      homeLoading: true
    }),
    getHomeDataSuccess: (state, { payload }) => ({
      ...state,
      homeLoading: false,
      gameCategory: payload.gameCategory
    }),
    getHomeDataFailure: (state) => ({
      ...state,
      homeLoading: false
    }),
    getProvidersStart: (state, { payload }) => ({
      ...state,
      providersLoading: true,
      categoryId: payload.categoryId
    }),
    getProvidersSuccess: (state, { payload }) => ({
      ...state,
      providersLoading: false,
      providers: payload
    }),
    getProvidersFailure: (state) => ({
      ...state,
      providersLoading: false
    }),
    toggleFavoriteStart: (state) => ({
      ...state
    }),
    toggleFavoriteSuccess: (state, { payload }) => ({
      ...state
    }),
    toggleFavoriteFailure: (state) => ({
      ...state
    }),
    getFilteredGamesStart: (state) => ({
      ...state,
      filteredLoading: true
    }),
    removeFilteredGames: (state) => ({
      ...state,
      filteredLoading: false,
      filteredGames: null
    }),
    getFilteredGamesSuccess: (state, { payload }) => {
      const {
        casinoGameListing = [], pageInfo = {
          currentPage: 1, totalPages: 1
        }
      } = payload
      let games = {}
      const { currentPage, totalPages } = pageInfo

      if (Number(currentPage) === 1) {
        games = { casinoGameListing, pageInfo }
      } else {
        if (Number(currentPage) > Number(state.filteredGames.pageInfo.currentPage)) {
          games = {
            casinoGameListing: [...state.filteredGames.casinoGameListing, ...casinoGameListing],
            pageInfo: {
              ...state.filteredGames.pageInfo,
              currentPage,
              totalPages
            }

          }
        } else {
          games = { ...state.filteredGames }
        }
      }

      return ({
        ...state,
        filteredLoading: false,
        filteredGames: games
      })
    },
    getFilteredGamesFailure: (state) => ({
      ...state,
      filteredLoading: false
    }),
    removeFavoriteStart: (state) => ({
      ...state
    }),
    removeFavoriteSuccess: (state, { payload }) => ({
      ...state,
      favoriteGamesList: payload
    }),
    removeFavoriteFailure: (state) => ({
      ...state
    }),
    getTopGamesStart: (state) => ({
      ...state,
      loading: false
    }),
    getTopGamesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      topGames: payload
    }),
    getTopGamesFailure: (state) => ({
      ...state,
      loading: false
    }),
    getTopWinnersStart: (state) => ({
      ...state,
      loading: false
    }),
    getTopWinnersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      topWinners: payload
    }),
    getTopWinnersFailure: (state) => ({
      ...state,
      loading: false
    }),
    getCurrentWinnersStart: (state) => ({
      ...state,
      loading: false
    }),
    getCurrentWinnersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      currentWinners: payload
    }),
    getCurrentWinnersFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateGameSubCategorySuccess: (state, { payload }) => ({
      ...state,
      gameSubCategory: payload
    }),
    updateRowsFetched: (state, { payload }) => ({
      ...state,
      rowsFetchedCount: payload
    }),
    getCategoryProviderListStart: (state) => ({
      ...state,
      providerListLoading: true
    }),
    getCategoryProviderListSuccess: (state, { payload }) => ({
      ...state,
      providerListLoading: false,
      categoryProviderList: payload
    }),
    getCategoryProviderListFailure: (state, { payload }) => ({
      ...state,
      providerListLoading: false,
      categoryProviderList: payload
    })
  }
})

export default casinoSlice.reducer

export const {
  resetGames,
  getGameSubCategoryStart,
  getGameSubCategorySuccess,
  getGameSubCategoryFailure,
  getGameStart,
  getHotGameStart,
  getNewGameStart,
  getGameSuccess,
  getGameFailure,
  getFavGameStart,
  getFavGameSuccess,
  getFavGameFailure,
  getGameCategoryStart,
  getGameCategorySuccess,
  getGameCategoryFailure,
  getHomeDataStart,
  getHomeDataSuccess,
  getHomeDataFailure,
  getProvidersStart,
  getProvidersSuccess,
  getProvidersFailure,
  toggleFavoriteStart,
  toggleFavoriteSuccess,
  toggleFavoriteFailure,
  getFilteredGamesStart,
  getFilteredGamesSuccess,
  getFilteredGamesFailure,
  removeFavoriteStart,
  removeFavoriteSuccess,
  removeFavoriteFailure,
  getTopGamesStart,
  getTopGamesSuccess,
  getTopGamesFailure,
  getTopWinnersStart,
  getTopWinnersSuccess,
  getTopWinnersFailure,
  getCurrentWinnersStart,
  getCurrentWinnersSuccess,
  getCurrentWinnersFailure,
  resetCasinoData,
  updateGameSubCategorySuccess,
  updateRowsFetched,
  getCategoryProviderListStart,
  getCategoryProviderListSuccess,
  getCategoryProviderListFailure,
  getTopSlotsGamesStart,
  getTopSlotsGamesSuccess,
  getTopSlotsGamesFailure,
  getPlayGameStart,
  getPlayGameSuccess,
  getPlayGameFailure,
  removeFilteredGames,
  getRecommendedGamesStart,
  getRecommendedGamesSuccess,
  getRecommendedGamesFailure,
  resetPlayGameData,
  setMappedCategory,
  updateGames,
  updateTopSlotsGames,
  updateHomeGameDataStart,
  updateHomeGameDataComplete,
  updateHomeGameDataFailure
} = casinoSlice.actions
