import React from 'react'
import { Routes as AppRoutes } from '../utils/routes'

const Promotion = React.lazy(() => import('../pages/Promotion'))
const Winnings = React.lazy(() => import('../pages/Winnings'))
const Achievements = React.lazy(() => import('../pages/Acheivements'))
const BonusDetails = React.lazy(() => import('../pages/BonusDetails'))
const VoucherGiftCard = React.lazy(() => import('../pages/VoucherGiftCard'))
const SportsDetail = React.lazy(() => import('../pages/Sportsbook/Pages/SportsDetail'))
const SportsTransaction = React.lazy(() => import('../pages/Sportsbook/Pages/SportsTransaction'))
const MyBets = React.lazy(() => import('../pages/Sportsbook/Pages/MyBets'))
const Results = React.lazy(() => import('../pages/Sportsbook/Pages/Results'))
const Favorites = React.lazy(() => import('../pages/Sportsbook/Pages/Favorites'))
const BonusTickets = React.lazy(() => import('../pages/BonusTickets'))
const Kyc = React.lazy(() => import('../pages/Kyc'))
const Cms = React.lazy(() => import('../pages/Cms'))
const Setting = React.lazy(() => import('../components/Setting/Setting'))
const Mybet = React.lazy(() => import('../components/MyBet/Mybet'))
const Transaction = React.lazy(() => import('../components/Transaction/Transaction'))
const Allprovider = React.lazy(() => import('../pages/Casino/components/AllProvider/Allprovider'))
const Favourites = React.lazy(() => import('../pages/Favourites'))
const ViewAllGames = React.lazy(() => import('../pages/ViewAllGames/ViewAllGames'))
const Provider = React.lazy(() => import('../pages/Casino/components/Provider'))
const UserLevel = React.lazy(() => import('../pages/UserLevel'))

const Casino = React.lazy(() => import('../pages/Casino'))
const PlayGame = React.lazy(() => import('../pages/PlayGame'))
const NotFound = React.lazy(() => import('../pages/NotFound'))

export const casinoRoutesList = [
  {
    ComponentName: 'casino',
    path: AppRoutes.casino,
    element: <Casino />,
    isPrivateRoute: false
  },
  {
    ComponentName: 'bonus-details',
    path: AppRoutes.bonusDetails,
    element: <BonusDetails />,
    isPrivateRoute: true
  },
  {
    ComponentName: 'MyBet',
    path: AppRoutes.mybet,
    element: <Mybet />,
    isPrivateRoute: true

  },
  {
    ComponentName: 'BonusTickets',
    path: AppRoutes.bonusTickets,
    element: <BonusTickets />,
    isPrivateRoute: true

  },
  {
    ComponentName: 'favourites',
    path: AppRoutes.favourites,
    element: <Favourites />,
    isPrivateRoute: true
  },
  {
    ComponentName: 'playGame',
    path: AppRoutes.playGame,
    element: <PlayGame />,
    isPrivateRoute: true
  },

  {
    ComponentName: 'ViewAllGame',
    path: AppRoutes.viewAllGame,
    element: <ViewAllGames />,
    isPrivateRoute: false
  },
  {
    ComponentName: 'ProviderGames',
    path: AppRoutes.providerGames,
    element: <ViewAllGames key='providerGames' />,
    isPrivateRoute: false
  },
  {
    ComponentName: 'Provider',
    path: AppRoutes.provider,
    element: <Provider />,
    isPrivateRoute: false
  },
  {
    ComponentName: 'AllProvider',
    path: AppRoutes.allprovider,
    element: <Allprovider />,
    isPrivateRoute: false
  }

]
export const privateRoutesList = [
  {
    ComponentName: 'kyc',
    path: AppRoutes.kyc,
    element: <Kyc />
  },
  {
    ComponentName: 'userLevel',
    path: AppRoutes.userLevel,
    element: <UserLevel />
  },
  {
    ComponentName: 'Setting',
    path: AppRoutes.setting,
    element: <Setting />
  },
  {
    ComponentName: 'Transaction',
    path: AppRoutes.transaction,
    element: <Transaction />
  },
  {
    ComponentName: 'MyVoucher',
    path: AppRoutes.myvoucher,
    element: <VoucherGiftCard />
  },
  {
    ComponentName: 'Achievements',
    path: AppRoutes.achievements,
    element: <Achievements />
  }

]
export const publicRoutesList = [
  {
    ComponentName: 'cms',
    path: AppRoutes.cms,
    element: <Cms />
  },
  {
    ComponentName: 'notFound',
    path: AppRoutes.notFound,
    element: <NotFound />
  },
  {
    ComponentName: 'winnings',
    path: AppRoutes.winnings,
    element: <Winnings />
  },
  {
    ComponentName: 'promotions',
    path: AppRoutes.promotions,
    element: <Promotion />
  }

]

export const sportsRoutesList = [
  {
    ComponentName: 'SportsDetail',
    path: AppRoutes.sportsDetail,
    element: <SportsDetail />,
    isPrivateRoute: false
  },
  {
    ComponentName: 'SportsTransaction',
    path: AppRoutes.sportsTransaction,
    element: <SportsTransaction />,
    isPrivateRoute: false
  },
  {
    ComponentName: 'MyBets',
    path: AppRoutes.myBets,
    element: <MyBets />,
    isPrivateRoute: false
  },
  {
    ComponentName: 'Results',
    path: AppRoutes.results,
    element: <Results />,
    isPrivateRoute: false
  },
  {
    ComponentName: 'Favorites',
    path: AppRoutes.favorites,
    element: <Favorites />,
    isPrivateRoute: false
  }
]
