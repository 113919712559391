import { createSlice } from '@reduxjs/toolkit'
import { getItem } from '../../utils/storageUtils'
// import { languageCodeList } from '../../constants/languageList'
// import { getItem } from '../../utils/storageUtils'

// const userLang = navigator.language.toUpperCase().substring(0, 2) || navigator.userLanguage.toUpperCase().substring(0, 2)

// let selectedLanguageCode = ''
// let selectedLanguage = ''

// if (window?.location?.origin === 'https://www.dino3.com') {
//   selectedLanguageCode = getItem('language') ? getItem('language')?.toUpperCase() : 'AU'
//   selectedLanguage = getItem('language') ? languageCodeList?.[getItem('language')] : languageCodeList.AU
// } else if (window?.location?.origin === 'https://www.dino2.com') {
//   selectedLanguageCode = getItem('language') ? getItem('language')?.toUpperCase() : 'FR'
//   selectedLanguage = getItem('language') ? languageCodeList?.[getItem('language')] : languageCodeList.FR
// } else {
//   selectedLanguageCode = getItem('language') ? getItem('language')?.toUpperCase() : window?.location?.pathname?.split('/')?.[1]?.toUpperCase() || languageCodeList?.[userLang] ? userLang?.toUpperCase() : 'EN'
//   selectedLanguage = getItem('language') ? languageCodeList?.[getItem('language')] : languageCodeList?.[window?.location?.pathname?.split('/')?.[1]?.toUpperCase()] || languageCodeList?.[userLang] ? languageCodeList?.[userLang] : languageCodeList.EN
// }

const languageSlice = createSlice({
  name: 'language',
  initialState: {
    languagesList: [],
    loading: false,
    selectedLanguage: getItem('language') || '',
    selectedLanguageCode: getItem('language') || '',
    showUserLang: true,
    languageLoading: true
  },
  reducers: {
    getLanguagesStart: (state) => ({
      ...state,
      loading: true
    }),
    getLanguagesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      languagesList: payload,
      languageLoading: false

    }),
    getLanguagesFailure: (state) => ({
      ...state,
      loading: false,
      languageLoading: false
    }),
    setSelectedLanguagesStart: (state) => ({
      ...state,
      loading: true,
      languageChanged: true
    }),
    setSelectedLanguagesComplete: (state, { payload }) => ({
      ...state,
      loading: false,
      selectedLanguage: payload?.selectedLanguage,
      selectedLanguageCode: payload?.selectedLanguageCode,
      languageChanged: false
    }),
    getLanguageDataStart: (state) => ({
      ...state,
      loading: true
    }),
    getLanguageDataSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      languageData: payload
    }),
    getLanguageDataFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateUserLangSuccess: (state) => ({
      ...state,
      showUserLang: false
    })
  }
})

export default languageSlice.reducer

export const {
  getLanguagesStart,
  getLanguagesSuccess,
  getLanguagesFailure,
  setSelectedLanguagesStart,
  setSelectedLanguagesComplete,
  getLanguageDataStart,
  getLanguageDataSuccess,
  getLanguageDataFailure,
  updateUserLangSuccess
} = languageSlice.actions
