import { takeLatest, put, select } from 'redux-saga/effects'
import { toast } from '../../components/Toast'
import { getGiftCards, getVouchers, redeemGiftCard, redeemVoucher, activePauseGiftCard, getGiftCardProgress } from '../../utils/apiCalls'

import {
  getVoucherStart,
  getVoucherSuccess,
  getVoucherFailure,
  redeemVoucherStart,
  redeemVoucherSuccess,
  redeemVoucherFailure,
  getGiftCardStart,
  getGiftCardSuccess,
  getGiftCardFailure,
  redeemGiftCardStart,
  redeemGiftCardSuccess,
  redeemGiftCardFailure,
  activePauseGiftCardStart,
  activePauseGiftCardSuccess,
  updateGiftCard,
  updateVoucherCard,
  getGiftCardProgressStart,
  getGiftCardProgressSuccess,
  getGiftCardProgressFailure
} from '../redux-slices/voucher'
import { t } from 'i18next'

export default function * voucherWatcher () {
  yield takeLatest(getVoucherStart.type, getVouchersWorker)
  yield takeLatest(getGiftCardProgressStart.type, getGiftCardProgressWorker)
  yield takeLatest(activePauseGiftCardStart.type, activePauseGiftCardWorker)
  yield takeLatest(redeemVoucherStart.type, redeemVoucherWorker)
  yield takeLatest(getGiftCardStart.type, getGiftCardsWorker)
  yield takeLatest(redeemGiftCardStart.type, redeemGiftCardWorker)
}

function * getGiftCardProgressWorker (action) {
  try {
    const { data } = yield getGiftCardProgress(action.payload)
    yield put(getGiftCardProgressSuccess(data?.data?.checkExistingVoucher))
  } catch (e) {
    yield put(getGiftCardProgressFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getVouchersWorker (action) {
  try {
    const { data } = yield getVouchers(action.payload)
    yield put(getVoucherSuccess(data?.data?.voucherDetail))
  } catch (e) {
    yield put(getVoucherFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * redeemVoucherWorker (action) {
  const { vouchers } = yield select(state => state.voucher)
  try {
    const { formData } = action.payload
    const res = yield redeemVoucher(formData)
    if (res.status === 200) {
      yield put(redeemVoucherSuccess())
      yield toast(t('redeemSuccess'), 'success')
      const tempArr = vouchers?.rows?.map((item) => {
        if (item?.voucherId === formData.voucherId) {
          return { ...item, availableRedeemCount: item?.availableRedeemCount > 0 ? Number(item?.availableRedeemCount) - 1 : 0 }
        } else {
          return item
        }
      })
      yield put(updateVoucherCard({ ...vouchers, rows: tempArr }))
    }
  } catch (e) {
    yield put(redeemVoucherFailure(e?.response?.data?.errors[0]?.description))
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getGiftCardsWorker (action) {
  try {
    const { data } = yield getGiftCards(action.payload)
    yield put(getGiftCardSuccess(data?.data?.giftCardVoucher))
  } catch (e) {
    yield put(getGiftCardFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * redeemGiftCardWorker (action) {
  try {
    const { data } = yield redeemGiftCard(action.payload)
    yield put(redeemGiftCardSuccess())
    yield toast(data?.data.message, 'success')
    yield put(getGiftCardStart({ limit: 10, pageNo: 1, search: '' }))
  } catch (e) {
    yield put(redeemGiftCardFailure(e?.response?.data?.errors[0]?.description))
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}
function * activePauseGiftCardWorker (action) {
  const { giftCards } = yield select(state => state.voucher)
  try {
    const { formData } = action.payload
    const res = yield activePauseGiftCard(action.payload)
    if (res.status === 200) {
      yield toast(formData?.isActiveByUser ? t('activateSuccess') : t('pauseSuccess'), 'success')
      const tempArr = giftCards?.rows?.map((item) => {
        if (item?.voucherId === formData.voucherId) {
          return { ...item, isActiveByUser: formData?.isActiveByUser }
        } else {
          return item
        }
      })
      yield put(updateGiftCard({ ...giftCards, rows: tempArr }))
    }
    yield put(activePauseGiftCardSuccess())
  } catch (e) {
    yield put(activePauseGiftCardSuccess())
    yield put(redeemGiftCardFailure(e?.response?.data?.errors[0]?.description))
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}
