import React from 'react'
import { ErrorMessage } from 'formik'
const SelectField = ({ label, options = [], defaultValue, firstOption, name, isNonFormik = false, error, ...rest }) => {
  return (
    <>
      <div id={name}>
        <div className='d-flex flex-column gap-1 form-input-wrapper'>
          {label && <label htmlFor={name}>{label}</label>}
          <div className='w-100 position-relative'>
            <select className='w-100' name={name} {...rest} defaultValue={defaultValue}>
              {firstOption}
              {options?.map((option) => {
                return <option key={option?.value} value={option?.value}>{option?.label}</option>
              })}

            </select>
            <div className='icon-right select-arrow'> <img src='/icon/arrow.svg' alt='icon' /></div>
          </div>

        </div>
        {isNonFormik
          ? null
          : <ErrorMessage
              component='div'
              name={name}
              key={name}
              className='error-message'
            />}
      </div>
    </>
  )
}

export default SelectField
