import { createSlice } from '@reduxjs/toolkit'

const betsSlice = createSlice({
  name: 'bets',
  initialState: {
    loading: false,
    myBets: {},
    allBets: {},
    tenantLeaderBoard: {},
    betInfo: null,
    rollingLoading: false,
    rollingContest: {},
    rollingContestList: {},
    events: {
      rows: [],
      count: 0
    },
    eventLoading: false
  },
  reducers: {
    updateEventList: (state, { payload }) => {
      const tempArr = [...state.events.rows]
      if (payload.id) {
        const updatedRows = tempArr.filter(event => event.competitionId !== payload.id)
        return {
          ...state,
          events: {
            rows: updatedRows,
            count: updatedRows.length
          }
        }
      } else {
        return {
          ...state,
          events: {
            rows: [payload.data, ...tempArr],
            count: state.events.count + 1
          }
        }
      }
    },
    getTenantLeaderBoardStart: (state) => ({
      ...state,
      loading: true
    }),
    getTenantLeaderBoardSuccess: (state, { payload }) => {
      const { leaderboard, pagination } = payload
      let tenantLeaderBoard = {}
      const { currentPage, totalPages } = pagination

      if (Number(currentPage) === 1) {
        tenantLeaderBoard = { leaderboard, pagination }
      } else {
        if (Number(currentPage) > Number(state.tenantLeaderBoard.pagination.currentPage)) {
          tenantLeaderBoard = {
            leaderboard: [...state.tenantLeaderBoard.leaderboard, ...leaderboard],
            pagination: {
              ...state.tenantLeaderBoard.pagination,
              currentPage,
              totalPages
            }
          }
        } else {
          tenantLeaderBoard = { ...state.tenantLeaderBoard }
        }
      }

      return ({
        ...state,
        loading: false,
        tenantLeaderBoard: tenantLeaderBoard
      })
    },
    getTenantLeaderBoardFailure: (state) => ({
      ...state,
      loading: false
    }),
    getMyBetsStart: (state) => ({
      ...state,
      loading: true
    }),
    getMyBetsSuccess: (state, { payload }) => {
      const { responseObject, pageInfo } = payload
      let myBets = {}
      const { currentPage, totalPages } = pageInfo

      if (Number(currentPage) === 1) {
        myBets = { responseObject, pagination: pageInfo }
      } else {
        if (Number(currentPage) > Number(state.myBets.pagination.currentPage)) {
          myBets = {
            responseObject: [...state.myBets.responseObject, ...responseObject],
            pagination: {
              ...state.myBets.pagination,
              currentPage,
              totalPages
            }
          }
        } else {
          myBets = { ...state.myBets }
        }
      }
      return ({
        ...state,
        loading: false,
        myBets: myBets
      })
    },
    getMyBetsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllBetsStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllBetsSuccess: (state, { payload }) => {
      const { responseObject, pageInfo } = payload
      let allBets = {}
      const { currentPage, totalPages } = pageInfo

      if (Number(currentPage) === 1) {
        allBets = { responseObject, pagination: pageInfo }
      } else {
        if (Number(currentPage) > Number(state.allBets.pagination.currentPage)) {
          allBets = {
            responseObject: [...state.allBets.responseObject, ...responseObject],
            pagination: {
              ...state.allBets.pagination,
              currentPage,
              totalPages
            }
          }
        } else {
          allBets = { ...state.allBets }
        }
      }
      return ({
        ...state,
        loading: false,
        allBets: allBets
      })
    },
    getAllBetsFailure: (state) => ({
      ...state,
      loading: false
    }),
    setBetInfo: (state, { payload }) => ({
      ...state,
      betInfo: payload
    }),
    getRollingContestStatus: (state, { payload }) => ({
      ...state,
      rollingLoading: true
    }),
    getRollingContestStatusSuccess: (state, { payload }) => ({
      ...state,
      rollingLoading: false,
      rollingContest: payload
    }),
    getRollingContestStatusFailure: (state) => ({
      ...state,
      rollingLoading: false
    }),
    getRollingContestStart: (state) => ({
      ...state,
      rollingContestLoading: true
    }),
    getRollingContestSuccess: (state, { payload }) => {
      const { responseObject, pageInfo } = payload
      let rollingContestList = {}
      const { currentPage, totalPages } = pageInfo

      if (Number(currentPage) === 1) {
        rollingContestList = { responseObject, pagination: pageInfo }
      } else {
        if (Number(currentPage) > Number(state.rollingContestList.pagination.currentPage)) {
          rollingContestList = {
            responseObject: [...state.rollingContestList.responseObject, ...responseObject],
            pagination: {
              ...state.rollingContestList.pagination,
              currentPage,
              totalPages
            }
          }
        } else {
          rollingContestList = { ...state.rollingContestList }
        }
      }
      return ({
        ...state,
        rollingContestLoading: false,
        rollingContestList: rollingContestList
      })
    },
    getRollingContestFailure: (state) => ({
      ...state,
      rollingContestLoading: false
    }),
    getEventsStart: (state) => ({
      ...state,
      eventLoading: true
    }),
    getEventsSuccess: (state, { payload }) => ({
      ...state,
      events: payload,
      eventLoading: false
    }),
    getEventsFailure: (state, { payload }) => ({
      ...state,
      eventLoading: false
    })
  }
})

export default betsSlice.reducer

export const {
  updateEventList,
  getEventsStart,
  getEventsSuccess,
  getEventsFailure,
  getRollingContestStart,
  getRollingContestSuccess,
  getRollingContestFailure,
  getMyBetsStart,
  getMyBetsSuccess,
  getMyBetsFailure,
  getAllBetsStart,
  getAllBetsSuccess,
  getAllBetsFailure,
  getTenantLeaderBoardStart,
  getTenantLeaderBoardSuccess,
  getTenantLeaderBoardFailure,
  setBetInfo,
  getRollingContestStatus,
  getRollingContestStatusSuccess,
  getRollingContestStatusFailure
} = betsSlice.actions
