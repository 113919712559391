import { takeLatest, put, select } from 'redux-saga/effects'
import { getLanguageData, getLanguages } from '../../utils/apiCalls'
import { initialLang, initialLangCode } from '../../utils/constants/index'
import { getItem, setItem } from '../../utils/storageUtils'
import { getLanguageDataFailure, getLanguageDataStart, getLanguageDataSuccess, getLanguagesFailure, getLanguagesStart, getLanguagesSuccess, setSelectedLanguagesComplete, setSelectedLanguagesStart } from '../redux-slices/language'
// import { showBanner, showChildren, showHeader } from '../redux-slices/loader'
import i18n from '../../components/Locale'

export default function * languageWatcher () {
  yield takeLatest(getLanguagesStart.type, getLanguagesWorker)
  yield takeLatest(setSelectedLanguagesStart.type, setSelectedLanguageWorker)
  yield takeLatest(getLanguageDataStart.type, getLanguageDataWorker)
}

const getLangData = state => state.language.languageData
const getselectedLanguageCode = state => state.language.selectedLanguageCode
// const getLang = state => state.language.languages

function * getLanguagesWorker (action) {
  try {
    const { data: response } = yield getLanguages()

    yield put(getLanguagesSuccess(response?.data?.mappedLanguageNames))
  } catch (e) {
    yield put(getLanguagesFailure())
  }
}

function * setSelectedLanguageWorker (action) {
  try {
    const { selectedLanguage, selectedLanguageCode } = action && action.payload
    yield put(setSelectedLanguagesComplete({
      selectedLanguage: selectedLanguage || initialLang,
      selectedLanguageCode: selectedLanguageCode || initialLangCode
    }))
    yield setItem('language', selectedLanguageCode)
    yield i18n.changeLanguage(selectedLanguageCode)
    // yield put(getLanguageDataStart())
  } catch (e) {
    yield put(setSelectedLanguagesComplete())
  }
}

function * getLanguageDataWorker (action) {
  try {
    const data = action && action.payload
    const languageData = yield select(getLangData)
    const selectedLanguageCode = yield select(getselectedLanguageCode)

    if (languageData?.language !== selectedLanguageCode) {
      const res = yield getLanguageData({ manual: !data })

      yield put(getLanguageDataSuccess(res?.data?.data?.languageData))
    }

    // yield put(showHeader(true))

    // yield put(showChildren(true))

    // yield put(showBanner(true))
  } catch (e) {
    const { navigate } = action?.payload

    if (e?.response?.data?.errors[0]?.errorCode === 3000) {
      navigate && navigate(`/${getItem('language')?.toLowerCase()}/600`)
    }
    yield put(getLanguageDataFailure())
  }
}
