import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Button from './components/form-elements/Button/Button'
class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch (error, errorInfo) {
    console.log(error, errorInfo)
    this.setState({
      hasError: true
    })
  }

  handleReloadPage = () => {
    window.location.reload()
  };

  render () {
    const { t } = this.props
    if (this.state.hasError) {
      return (
        <div className='error-boundary'>
          <h2 className='text-center'>
            {t('error')}
          </h2>
          <div className='notFound text-center'>
            <p>
              {t('somethingWentWrong')}
            </p>
          </div>
          <Button variant='primary' title={t('refresh')} onClick={this.handleReloadPage} />
        </div>
      )
    }

    return this.props.children
  }
}

export default withTranslation()(ErrorBoundary)
