import { createSlice } from '@reduxjs/toolkit'
import { getItem } from '../../utils/storageUtils'

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    isWalletActionOpen: false,
    isLoginOpen: false,
    isSignUpOpen: false,
    isLogoutOpen: false,
    isForgetPasswordOpen: false,
    loading: false,
    isLoggedIn: JSON.parse(getItem('loggedIn')),
    success: false,
    regFields: {},
    tenantDetails: {},
    getAllCountries: {},
    verifyStatus: false,
    isConnected: false,
    themes: [],
    verifyUserLoading: false,
    logoutLoading: false
  },
  reducers: {
    forgetPasswordRequestStart: (state) => ({
      ...state,
      loading: true
    }),
    forgetPasswordRequestSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      isLoggedIn: true,
      success: true
    }),
    forgetPasswordRequestFailure: (state) => ({
      ...state,
      loading: false,
      isLoggedIn: false,
      success: false
    }),
    userLoginStart: (state) => ({
      ...state,
      loading: true
    }),
    setLoginModal: (state, { payload }) => ({
      ...state,
      isLoginOpen: payload
    }),
    setSignUpModal: (state, { payload }) => ({
      ...state,
      isSignUpOpen: payload
    }),
    setForgetPasswordModal: (state, { payload }) => ({
      ...state,
      isForgetPasswordOpen: payload

    }),
    setModalState: (state, { payload }) => ({
      ...state,
      [payload.key]: payload.value
    }),
    userLoginSuccess: (state) => ({
      ...state,
      loading: false,
      isLoggedIn: true,
      success: true
    }),
    userLoginFailure: (state) => ({
      ...state,
      loading: false,
      isLoggedIn: false,
      success: false
    }),
    getRegistrationFieldsStart: (state) => ({
      ...state,
      loading: true
    }),
    getRegistrationFieldsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      regFields: payload
    }),
    getRegistrationFieldsFailure: (state) => ({
      ...state,
      loading: false
    }),
    userSignUpStart: (state) => ({
      ...state,
      loading: true
    }),
    userSignUpComplete: (state) => ({
      ...state,
      loading: false
    }),
    getTenantDetailsStart: (state) => ({
      ...state,
      loading: true
    }),
    getTenantDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tenantDetails: payload
    }),
    getTenantDetailsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllCountriesStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllCountriesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      allCountries: payload
    }),
    getAllCountriesFailure: (state) => ({
      ...state,
      loading: false
    }),
    verifyEmailStart: (state) => ({
      ...state,
      loading: true
    }),
    verifyEmailComplete: (state, { payload }) => ({
      ...state,
      loading: false,
      verifyStatus: payload
    }),
    resendEmailStart: (state) => ({
      ...state,
      loading: true
    }),
    resendEmailComplete: (state) => ({
      ...state,
      loading: false
    }),
    forgetPasswordStart: (state) => ({
      ...state,
      loading: true
    }),
    forgetPasswordComplete: (state, { payload }) => ({
      ...state,
      loading: false,
      forgetPass: payload
    }),
    resetPasswordStart: (state) => ({
      ...state,
      loading: true
    }),
    resetPasswordComplete: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    setSocketClient: (state, { payload }) => ({
      ...state,
      client: payload
    }),
    logoutStart: (state) => ({
      ...state,
      logoutLoading: true
    }),
    logoutComplete: (state) => ({
      ...state,
      logoutLoading: false,
      isLoggedIn: false
    }),
    getThemesStart: (state) => ({
      ...state
    }),
    getThemesSuccess: (state, { payload }) => ({
      ...state,
      themes: payload
    }),
    getThemesFailure: (state) => ({
      ...state
    }),
    verifyUserStart: (state) => ({
      ...state,
      verifyUserLoading: true
    }),
    verifyUserComplete: (state) => ({
      ...state,
      verifyUserLoading: false
    }),
    checkRefreshTokenStart: (state) => ({
      ...state
    })
  }
})

export default loginSlice.reducer

export const {
  forgetPasswordRequestStart,
  forgetPasswordRequestSuccess,
  forgetPasswordRequestFailure,
  userLoginStart,
  userLoginSuccess,
  userLoginFailure,
  getRegistrationFieldsStart,
  getRegistrationFieldsSuccess,
  getRegistrationFieldsFailure,
  userSignUpStart,
  userSignUpComplete,
  getTenantDetailsStart,
  getTenantDetailsSuccess,
  getTenantDetailsFailure,
  getAllCountriesStart,
  getAllCountriesSuccess,
  getAllCountriesFailure,
  getUserDetailsSuccess,
  getUserDetailsFailure,
  verifyEmailStart,
  verifyEmailComplete,
  resendEmailStart,
  resendEmailComplete,
  forgetPasswordStart,
  forgetPasswordComplete,
  resetPasswordStart,
  resetPasswordComplete,
  setSocketClient,
  logoutStart,
  logoutComplete,
  getThemesStart,
  getThemesSuccess,
  getThemesFailure,
  setLoginModal,
  setSignUpModal,
  setForgetPasswordModal,
  setModalState,
  verifyUserStart,
  verifyUserComplete,
  checkRefreshTokenStart
} = loginSlice.actions
