import React from 'react'
import { toast as toastify } from 'react-toastify'
import { t } from 'i18next'
export const toast = (message, type) => {
  switch (type) {
    case 'success':
      return toastify.success(Toast({ message, title: 'success' }))

    case 'error':
      return toastify.error(Toast({ message: <p><span>{message?.charAt(0)}</span><span>{message?.slice(1)}</span></p>, title: 'error' }))

    case 'betsuccess':
      return toastify.warning(BetToast({ message: <p className='betslip-message'>{message}</p>, title: 'processing' }))

    case 'betfail':
      return toastify.error(BetToast({ message: <p className='betslip-message'>{message}</p>, title: 'error' }))

    default:
      return toastify.warning(Toast({ message, title: 'warning' }))
  }
}

const Toast = ({ message, title }) => (
  <div>
    <span className='toast-title'>{`${t(title)}`}</span>
    <span className='text-center'>{message}</span>
  </div>
)

const BetToast = ({ message, title }) => (
  <div className='toaster'>
    <span className='toast-title'>{`${t(title)}`}</span>
    <span className='text-center'>{message}</span>
  </div>
)
