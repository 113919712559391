import axios from 'axios'
import store from '../store'
import { getItem, getLoginToken } from './storageUtils'
import { initialLangCode } from './constants'
import { checkRefreshTokenStart, logoutStart } from '../store/redux-slices/login'

const axiosInstance = axios.create()

export const setupInterceptors = () => {
  axiosInstance.interceptors.response.use(
    (res) => res,
    async (error) => {
      if (error.response.status === 403) {
        const errorName = error?.response?.data?.errors?.[0]?.name
        if (errorName === 'TenanatAreBlocked' || errorName === 'UserInActive') {
          if (!getLoginToken()) {
            store.dispatch(logoutStart())
          }
        } else {
          store.dispatch(checkRefreshTokenStart())
        }
      }
      return Promise.reject(error)
    }
  )
}

const METHODS = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE'
}

const makeRequest = async (url, method, data = {}) => {
  const headers = {
    'Content-Type': 'application/json'
  }

  if (getLoginToken()) {
    headers.Authorization = `Bearer ${getLoginToken()}`
  }

  data = { ...data, languageCode: getItem('language') || initialLangCode }

  headers.language = getItem('language') || initialLangCode

  const axiosConfig = {
    url,
    method,
    data,
    headers
  }

  if (method === METHODS.get) {
    axiosConfig.params = data
  }

  return axiosInstance({
    ...axiosConfig
  })
}

const getRequest = (url, data) => makeRequest(url, METHODS.get, data)

const postRequest = (url, data) => makeRequest(url, METHODS.post, data)

const putRequest = (url, data) => makeRequest(url, METHODS.put, data)

const deleteRequest = (url, data) => makeRequest(url, METHODS.delete, data)

export { getRequest, postRequest, putRequest, deleteRequest }
