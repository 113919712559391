import React from 'react'
import ReactDOM from 'react-dom/client'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './fonts/index.css'
import 'react-toastify/dist/ReactToastify.css'
import { setupInterceptors } from './utils/axios'
import App from './App'
import './scss/main.scss'

// const Tournaments = React.lazy(() => import('./pages/Tournament'))

const root = ReactDOM.createRoot(document.getElementById('root'))

setupInterceptors()

root.render(
  <App />
)
