import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import VaultDepositWithdraw from '../../../Wallets/VaultDepositWithdraw'
import { setToggleState } from '../../../../store/redux-slices/toggle'
import { useDispatch } from 'react-redux'
const VaultActionModal = ({ isOpen }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const handleVaultActionModalClose = () => {
    dispatch(setToggleState({ key: 'isVaultActionModalOpen', value: false }))
  }

  return (
    <>
      <Modal className='transaction-modal' show={isOpen} onHide={handleVaultActionModalClose} centered>
        <Modal.Header closeButton className='d-flex justify-content-center'>
          <Modal.Title className='w-100 text-center'> {t('vault')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='modal-tabs'>
            <VaultDepositWithdraw handleClose={handleVaultActionModalClose} />
          </div>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default VaultActionModal
