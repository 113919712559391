import React from 'react'
import './button.scss'

const Button = ({ className, type = 'button', icon, disabled, loading, variant, title, ...rest }) => {
  const variantStyle = {
    primary: 'primary',
    default: 'default',
    ghost: 'ghost',
    secondaryGradient: 'secondaryGradient'
  }
  return (
    <button type={type} disabled={loading || disabled} className={`button-element ${variantStyle[variant || 'default']}  ${className}`} {...rest}> {icon && <img src={icon} alt='icon' />}  {loading ? 'Loading...' : title}</button>
  )
}

export default Button
