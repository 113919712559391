export const Routes = {
  homepage: '/',
  playGame: '/casino/play/:gameId/:gameCategory/:providerId',
  notFound: '/casino/404',
  forbidden: '/casino/403',
  casino: '/casino',
  favourites: '/casino/favourites',
  setting: '/setting',
  affiliate: '/affiliate',
  mybet: '/casino/mybet',
  transaction: '/transaction',
  viewAllGame: '/casino/viewAllGame/:category/:name',
  providerGames: '/casino/providerAllGame/:providerId',
  provider: '/casino/provider',
  allprovider: '/casino/allprovider',
  comingsoon: '/casino/comingsoon',
  bonusTickets: '/casino/bonus-tickets',
  event: '/casino/event',
  myvoucher: '/casino/my-voucher',
  bonusDetails: '/casino/bonus-details',

  // other
  cms: '/categories/:slug/:cmsPageId',
  kyc: '/kyc',
  userLevel: '/user-level',
  achievements: '/achievements',
  promotions: '/promotions',
  winnings: '/winnings',

  // sportsbook
  sportsbook: '/sportsbook',
  sportsDetail: '/sportsbook/:matchId',
  sportsTransaction: '/sportsbook/transaction',
  myBets: '/sportsbook/mybets',
  results: '/sportsbook/results',
  favorites: '/sportsbook/favorites'
}
