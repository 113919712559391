import { createSlice } from '@reduxjs/toolkit'

const voucherSlice = createSlice({
  name: 'voucher',
  initialState: {
    loading: false,
    redeemLoading: false,
    updateLoading: false,
    vouchers: {},
    giftCards: {},
    progressDataLoading: false,
    giftCardProgress: {}
  },
  reducers: {
    getVoucherStart: (state) => ({
      ...state,
      loading: true
    }),
    getVoucherSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      vouchers: payload
    }),
    getVoucherFailure: (state) => ({
      ...state,
      loading: false
    }),
    redeemVoucherStart: (state) => ({
      ...state,
      redeemLoading: true
    }),
    redeemVoucherSuccess: (state, { payload }) => ({
      ...state,
      redeemLoading: false
    }),
    redeemVoucherFailure: (state) => ({
      ...state,
      redeemLoading: false
    }),
    getGiftCardStart: (state) => ({
      ...state,
      loading: true
    }),
    getGiftCardSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      giftCards: payload
    }),
    getGiftCardFailure: (state) => ({
      ...state,
      loading: false
    }),
    redeemGiftCardStart: (state) => ({
      ...state,
      redeemLoading: true
    }),
    redeemGiftCardSuccess: (state, { payload }) => ({
      ...state,
      redeemLoading: false
    }),
    redeemGiftCardFailure: (state) => ({
      ...state,
      redeemLoading: false
    }),
    activePauseGiftCardStart: (state) => ({
      ...state,
      updateLoading: true
    }),
    activePauseGiftCardSuccess: (state, { payload }) => ({
      ...state,
      updateLoading: false
    }),
    updateGiftCard: (state, { payload }) => ({
      ...state,
      giftCards: payload
    }),
    updateVoucherCard: (state, { payload }) => ({
      ...state,
      vouchers: payload
    }),
    getGiftCardProgressStart: (state) => ({
      ...state,
      progressDataLoading: true
    }),
    getGiftCardProgressSuccess: (state, { payload }) => ({
      ...state,
      progressDataLoading: false,
      giftCardProgress: payload
    }),
    getGiftCardProgressFailure: (state, { payload }) => ({
      ...state,
      progressDataLoading: false,
      giftCardProgress: {}
    })
  }
})
export const {
  getGiftCardProgressStart,
  getGiftCardProgressSuccess,
  getGiftCardProgressFailure,
  updateVoucherCard,
  updateGiftCard,
  activePauseGiftCardStart,
  activePauseGiftCardSuccess,
  getVoucherStart,
  getVoucherSuccess,
  getVoucherFailure,
  redeemVoucherStart,
  redeemVoucherSuccess,
  redeemVoucherFailure,
  getGiftCardStart,
  getGiftCardSuccess,
  getGiftCardFailure,
  redeemGiftCardStart,
  redeemGiftCardSuccess,
  redeemGiftCardFailure
} = voucherSlice.actions

export default voucherSlice.reducer
