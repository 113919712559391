import { createSlice } from '@reduxjs/toolkit'

const cmsSlice = createSlice({
  name: 'cms',
  initialState: {
    loading: false,
    tenantConfiguration: {},
    banners: {},
    cmsDetails: {},
    cmsPageLoading: false,
    bannersLoading: false,
    footerCms: {},
    footerCmsLoading: false
  },
  reducers: {
    getTenantsConfigurationStart: (state) => ({
      ...state,
      loading: true
    }),
    getTenantsConfigurationSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tenantConfiguration: payload
    }),
    getTenantsConfigurationFailure: (state) => ({
      ...state,
      loading: false
    }),
    getTenantsBannersStart: (state) => ({
      ...state,
      bannersLoading: true
    }),
    getTenantsBannersSuccess: (state, { payload }) => ({
      ...state,
      bannersLoading: false,
      banners: payload
    }),
    getTenantsBannersFailure: (state) => ({
      ...state,
      bannersLoading: false
    }),
    getCMSPageStart: (state) => ({
      ...state,
      cmsPageLoading: true
    }),
    getCMSPageSuccess: (state, { payload }) => ({
      ...state,
      cmsPageLoading: false,
      cmsDetails: payload
    }),
    getCMSPageFailure: (state) => ({
      ...state,
      cmsPageLoading: false
    }),
    getFooterCmsStart: (state) => ({
      ...state,
      footerCmsLoading: true
    }),
    getFooterCmsSuccess: (state, { payload }) => ({
      ...state,
      footerCmsLoading: false,
      footerCms: payload
    }),
    getFooterCmsFailure: (state) => ({
      ...state,
      footerCmsLoading: false
    })
  }
})
export const {
  getTenantsConfigurationStart,
  getTenantsConfigurationSuccess,
  getTenantsConfigurationFailure,
  getCMSPageStart,
  getCMSPageSuccess,
  getCMSPageFailure,
  getTenantsBannersStart,
  getTenantsBannersSuccess,
  getTenantsBannersFailure,
  getFooterCmsStart,
  getFooterCmsSuccess,
  getFooterCmsFailure
} = cmsSlice.actions

export default cmsSlice.reducer
