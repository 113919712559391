export const getDeviceType = (isBetRadar) => {
  const height = window.screen.height
  const width = window.screen.width
  if (height <= 950 && width <= 480) {
    return 'mobile'
  } else if (width <= 1199 && height <= 1180) {
    return 'mobile'
  } else {
    return isBetRadar ? 'internet' : 'desktop'
  }
}
