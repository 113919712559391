import * as Yup from 'yup'

// Schema for EN with Extra Validations
export const passwordValidationEn = (t) =>
  Yup.object().shape({
    password:
      Yup.string()
        .required(t('passwordRequired'))

  })

// Schema for Other Languages
export const passwordValidation = (t) =>
  Yup.object().shape({
    password:
    Yup.string()
      .required(t('passwordRequired'))
  })
