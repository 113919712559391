import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import { Form, Formik } from 'formik'
import { getItem } from '../../../../utils/storageUtils'
import { passwordValidation, passwordValidationEn } from './schema'
import { Spinner } from 'react-bootstrap'
import { WALLET_ACTION } from '../../../../utils/constants'
import TextField from '../../../form-elements/TextField/TextField'
const VerifyPasswordModal = ({ isOpen, handleClose, onSubmit, loading, type }) => {
  const [toggleViewPassword, setToggleViewPassword] = useState(false)
  const { t } = useTranslation()
  const handleCloseModal = () => {
    handleClose()
  }
  return (
    <>
      <Modal className='transaction-modal' show={isOpen} onHide={handleCloseModal} centered>
        <Modal.Header closeButton className='d-flex justify-content-center'>
          <Modal.Title className='w-100 text-center'> {t('verifyUser')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='modal-tabs'>
            <Formik
              initialValues={{
                password: ''
              }}
              validationSchema={[' ', '', 'EN'].includes(getItem('language')?.toUpperCase()) ? passwordValidationEn(t) : passwordValidation(t)}
              onSubmit={onSubmit}
              validateOnChange
              validateOnBlur
            >
              {({
                values,
                handleChange,
                handleSubmit,
                handleBlur

              }) => {
                return (
                  <Form className=' signup-form1 d-flex flex-column gap-3'>

                    <div className=' d-flex  flex-column input-label'>

                      <div className='form-group icon_include position-relative password-info'>
                        <TextField
                          label={type === WALLET_ACTION.DEPOSIT || type === 'passwordVisibilty' ? t('passwordLabel') : t('withdrawalPasswordLabel')}
                          name='password'
                          type={toggleViewPassword ? 'text' : 'password'}
                          placeholder={type === WALLET_ACTION.DEPOSIT || type === 'passwordVisibilty' ? t('passwordPlaceholder') : t('passwordWithdrawalPlaceholder')}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={16}
                          onPaste={(e) => e.preventDefault()}
                          handleIconClick={
                            () =>
                              setToggleViewPassword(prev => !prev)
                          }
                          icon={toggleViewPassword
                            ? '/icon/Visible-eye.svg'
                            : '/icon/Close-eye.svg'}
                        />
                      </div>
                      <button
                        type='submit'
                        className='submit-btn justify-content-center '
                        disabled={loading}
                      >
                        {t('submit')}
                        {loading && (
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        )}
                      </button>

                    </div>
                  </Form>
                )
              }}
            </Formik>

          </div>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default VerifyPasswordModal
