import React from 'react'
import { ErrorMessage } from 'formik'
import CurrencyInput from 'react-currency-input-field'
const TextField = ({ label, name, type, error, handleIconClick, value, onChange, isCurrencyField, isNonFormik = false, icon, ...rest }) => {
  return (
    <div id={name}>
      <div className='d-flex flex-column gap-1 form-input-wrapper'>
        {label && <label htmlFor={name}>{label}</label>}
        <div className='w-100 position-relative'>
          {isCurrencyField
            ? <CurrencyInput
                name={name}
                intlConfig={{ locale: 'ko-KR' }}
                value={value}
                onValueChange={onChange}
                {...rest}
              />
            : <input value={value} type={type} className='w-100' onChange={onChange} name={name} {...rest} />}

          {icon && <div className='icon-right'> <img onClick={handleIconClick} src={icon} alt='icon' /></div>}
        </div>

      </div>
      {isNonFormik
        ? null
        : <ErrorMessage
            component='div'
            name={name}
            key={name}
            className='error-message'
          />}
    </div>
  )
}

export default TextField
