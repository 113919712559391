import Lottie from 'lottie-react'
import ScreenLoader from '../../../src/constants/jsons/dinonameani.json'
import Loading1 from '../../../src/constants/jsons/loading1.json'
import Loading2 from '../../../src/constants/jsons/loading2.json'
import { createPortal } from 'react-dom'
import { Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { isGamma } from '../../utils/constants'

const Loader = ({ top = 20, type = 'content', position = 'relative', zIndex, bottom = 20 }) => {
  const { theme } = useSelector(state => state.toggle)
  return (
    <>
      {type === 'page' && (
        <div
          className={'parent loader page-loader ' + theme}
          style={{ marginTop: top || '', position, zIndex, marginBottom: bottom || '' }}
        >
          <Lottie
            animationData={Loading2}
            loop
            autoplay
            style={{ width: 150, height: 150 }}
          />
        </div>
      )}
      {type === 'content' && (
        <div
          className={'parent loader ' + theme}
          style={{ marginTop: top || '', position, zIndex, marginBottom: bottom || '' }}
        >
          <Lottie
            animationData={Loading1}
            loop
            autoplay
            style={{ width: 150, height: 150 }}
          />
        </div>
      )}
      {type === 'spinnerScreen' && (
        createPortal(
          <div
            className={'parent loader screen-loader backdrop ' + theme}
          >
            <Spinner animation='border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>

          </div>, document.querySelector('#destination'))
      )}
      {type === 'screen' && (
        createPortal(
          <div
            className={'parent loader screen-loader ' + theme}
          >
            <Lottie
              animationData={isGamma ? Loading2 : ScreenLoader}
              loop
              autoplay

            />

          </div>, document.querySelector('#destination'))
      )}

    </>

  )
}

export default Loader
