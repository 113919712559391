import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { verifyUserStart } from '../../../store/redux-slices/login'
import { WALLET_ACTION } from '../../../utils/constants'
import { useSearchParams } from 'react-router-dom'

const useWalletAction = () => {
  const [searchParams] = useSearchParams()
  const typeOfWallet = searchParams.get('type')
  const { userDetails, currencies } = useSelector((state) => state.user)
  const { verifyUserLoading } = useSelector(state => state.login)
  const { walletActionLoading } = useSelector(state => state.transactions)
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false)
  const [isToViewAccNumber, setIsToViewAccNumber] = useState(false)
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false)
  const [showAccNumber, setShowAccNumber] = useState(false)
  const [data, setData] = useState({})
  const [actionType, setActionType] = useState(WALLET_ACTION.DEPOSIT)
  const dispatch = useDispatch()
  const verifyUserData = {
    type: data.actionSource,
    username: userDetails?.username,
    data: data,
    closeModal: () => {
      setIsPasswordModalOpen(false)
      if (isToViewAccNumber) {
        setShowAccNumber(true)
      }
    }
  }
  const handleSubmit = (values) => {
    dispatch(verifyUserStart({ ...verifyUserData, password: values.password }))
  }

  const handleWalletAction = (type, values) => {
    setData({ ...values, amount: Number(values.amount), actionSource: type, isVerified: true })
    setIsPasswordModalOpen(true)
  }
  useEffect(() => {
    if (typeOfWallet) { setActionType(typeOfWallet === 'deposit' ? WALLET_ACTION.DEPOSIT : WALLET_ACTION.WITHDRAW) }
  }, [typeOfWallet, searchParams])
  return {
    showAccNumber,
    setShowAccNumber,
    handleWalletAction,
    isPasswordModalOpen,
    setIsPasswordModalOpen,
    handleSubmit,
    verifyUserLoading,
    currencies,
    userDetails,
    data,
    actionType,
    setActionType,
    walletActionLoading,
    isVerifyModalOpen,
    setIsVerifyModalOpen,
    setIsToViewAccNumber,
    setData,
    searchParams
  }
}

export default useWalletAction
