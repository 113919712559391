import React from 'react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import store from './store'
import AppRoutes from './routes'
import ErrorBoundary from './ErrorBoundary'
import { SportsBookContext, useSportsBookContextValues } from './pages/Sportsbook/Context/SportsBookContext'

function App () {
  const { ...values } = useSportsBookContextValues()

  return (
    <ErrorBoundary>
      <SportsBookContext.Provider
        value={{ ...values }}
      >
        <Provider store={store}>
          <ToastContainer
            theme='colored'
            position='top-right'
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            limit={3}
          />
          <AppRoutes />

        </Provider>
      </SportsBookContext.Provider>
    </ErrorBoundary>
  )
}

export default App
