
import { createSlice } from '@reduxjs/toolkit'

const loaderSlice = createSlice({
  name: 'loader',
  initialState: {
    showHeader: true,
    showBanner: false,
    showChildren: true,
    showLeagueList: false,
    showRealPlayer: false,
    showFooter: true
  },
  reducers: {
    showHeader: (state, { payload }) => ({
      ...state,
      showHeader: payload
    }),
    showChildren: (state, { payload }) => ({
      ...state,
      showChildren: payload
    }),
    showBanner: (state) => ({
      ...state,
      showBanner: true
    }),
    showLeagueList: (state) => ({
      ...state,
      showLeagueList: true
    }),
    showRealPlayer: (state) => ({
      ...state,
      showRealPlayer: true
    }),
    showFooter: (state, { payload }) => ({
      ...state,
      showFooter: payload
    })
  }
})

export default loaderSlice.reducer

export const {
  showHeader,
  showBanner,
  showLeagueList,
  showRealPlayer,
  showFooter,
  showChildren
} = loaderSlice.actions
