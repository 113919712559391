import { takeLatest, put, select } from 'redux-saga/effects'

import { t } from 'i18next'
import { Buffer } from 'buffer'
import {
  userLogin,
  getRegistrationFields,
  userSignUp,
  getTenantDetails,
  getAllCountries,
  verifyEmail,
  resendEmail,
  forgetPassword,
  resetPassword,
  logout,
  getThemes,
  verifyUserPassword,
  checkRefreshToken,
  forgetPasswordRequest
} from '../../utils/apiCalls'

import {
  userLoginStart,
  userLoginSuccess,
  userLoginFailure,
  getRegistrationFieldsStart,
  getRegistrationFieldsSuccess,
  getRegistrationFieldsFailure,
  userSignUpStart,
  userSignUpComplete,
  getTenantDetailsStart,
  getTenantDetailsSuccess,
  getTenantDetailsFailure,
  getAllCountriesStart,
  getAllCountriesSuccess,
  getAllCountriesFailure,
  verifyEmailStart,
  verifyEmailComplete,
  resendEmailStart,
  resendEmailComplete,
  forgetPasswordStart,
  forgetPasswordComplete,
  resetPasswordStart,
  resetPasswordComplete,
  logoutStart,
  logoutComplete,
  getThemesStart,
  getThemesSuccess,
  getThemesFailure,
  verifyUserStart,
  verifyUserComplete,
  checkRefreshTokenStart,
  forgetPasswordRequestStart,
  forgetPasswordRequestSuccess,
  forgetPasswordRequestFailure,
  setForgetPasswordModal
} from '../redux-slices/login'
import {
  getItem,
  getRefreshToken,
  removeItem,
  removeLoginToken,
  setItem,
  setLoginToken,
  setRefreshToken
} from '../../utils/storageUtils'
import { toast } from '../../components/Toast'
import { Routes } from '../../utils/routes'
import { resetState } from '../redux-slices'
import { setSelectedLanguagesStart } from '../redux-slices/language'
import {
  getCashbackBalanceStart,
  getUserDetailsStart
} from '../redux-slices/user'
import {
  WALLET_ACTION,
  initialLang,
  initialLangCode
} from '../../utils/constants'
import {
  setVaultActionStart,
  setWalletActionStart
} from '../redux-slices/transactions'

export default function * loginWatcher () {
  yield takeLatest(forgetPasswordRequestStart.type, forgetPasswordRequestWorker)
  yield takeLatest(userLoginStart.type, userLoginWorker)
  yield takeLatest(checkRefreshTokenStart.type, checkRefreshTokenWorker)
  yield takeLatest(
    getRegistrationFieldsStart.type,
    getRegistrationFieldsWorker
  )
  yield takeLatest(userSignUpStart.type, userSignUpWorker)
  yield takeLatest(getTenantDetailsStart.type, getTenantDetailsWorker)
  yield takeLatest(getAllCountriesStart.type, getAllCountriesWorker)
  yield takeLatest(verifyEmailStart.type, verifyEmailWorker)
  yield takeLatest(resendEmailStart.type, resendEmailWorker)
  yield takeLatest(forgetPasswordStart.type, forgetPasswordWorker)
  yield takeLatest(resetPasswordStart.type, resetPasswordWorker)
  yield takeLatest(logoutStart.type, logoutWorker)
  yield takeLatest(getThemesStart.type, getThemesWorker)
  yield takeLatest(verifyUserStart.type, userVerifyWorker)
}

const getLanguageData = (state) => state?.language?.languageData

function * forgetPasswordRequestWorker (action) {
  try {
    const {
      username
    } = action && action.payload
    const { data } = yield forgetPasswordRequest({ username: username })
    toast(t('forgetPasswordRequestSent'), 'success')
    yield put(setForgetPasswordModal(false))
    yield put(forgetPasswordRequestSuccess(data?.data?.message))
  } catch (errors) {
    toast(errors.response.data.errors[0].description, 'error')
    yield put(forgetPasswordRequestFailure(errors))
    yield put(setForgetPasswordModal(false))
  }
}
function * userVerifyWorker (action) {
  try {
    const { username, password, closeModal, type, data, isVaultAction } =
      action && action.payload
    const encryptedPassword = Buffer.from(password).toString('base64')
    if (type === WALLET_ACTION.DEPOSIT) {
      const res = yield userLogin({
        email: username,
        password: encryptedPassword
      })
      if (res.status === 200) {
        yield closeModal()
      }
    } else if (type === WALLET_ACTION.WITHDRAW) {
      const { data } = yield verifyUserPassword({
        withdrawalPassword: encryptedPassword
      })
      if (data?.data?.message) {
        yield closeModal()
      }
    }

    if (isVaultAction) {
      yield put(setVaultActionStart({ ...data }))
    } else {
      const formData = { ...data }
      delete formData.isVerified
      if (data?.isVerified) {
        yield put(setWalletActionStart({ ...formData }))
      }
    }
    yield put(verifyUserComplete())
  } catch (e) {
    if (e?.response?.data?.errors[0]?.name === 'INCORRECT_CREDENTIAL') {
      yield toast(`${t('incorrectCredentials')}`, 'error')
    } else if (
      e?.response?.data?.errors[0]?.name === 'WithdrawlPasswordDoesNotMatch'
    ) {
      yield toast(`${t('withdrawlPasswordDoesNotMatch')}`, 'error')
    } else {
      yield toast(e.message, 'error')
    }
    yield put(verifyUserComplete())
  }
}
function * userLoginWorker (action) {
  try {
    const {
      username,
      password,
      navigate,
      returnTo,
      setShowModal,
      closeModal,
      isSignUp
    } = action && action.payload
    const encryptedPass = Buffer.from(password).toString('base64')
    const { data } = yield userLogin({
      email: username,
      password: encryptedPass
    })
    const {
      accessToken,
      refreshToken,
      userId,
      selfExclusion,
      expiration,
      locale
    } = data?.data
    const { selectedLanguage, selectedLanguageCode } = yield select(
      (state) => state.language
    )

    if (selfExclusion) {
      setShowModal({ show: true, expiration })
      yield put(userLoginFailure())
      return
    }

    yield setLoginToken(accessToken)
    yield setRefreshToken(refreshToken)
    yield put(userLoginSuccess())

    if (!isSignUp) {
      yield toast(`${t('loginSuccess')}`, 'success')
    }

    yield setItem('loggedIn', String(true))

    yield setItem('userId', String(userId))
    if (getItem('referralCode')) {
      yield removeItem('referralCode')
    }
    if (locale) {
      yield put(
        setSelectedLanguagesStart({
          selectedLanguage: selectedLanguage || initialLang,
          selectedLanguageCode:
            selectedLanguageCode || getItem('language') || initialLangCode,
          preSelectedLanguageCode: getItem('language'),
          navigate
        })
      )
    }

    yield put(getCashbackBalanceStart())
    navigate(`${returnTo || Routes.homepage}`)
    // window.location.href = returnTo || Routes.homepage

    if (returnTo) {
      window.locationPath = null
    }
    yield closeModal()
    yield put(getUserDetailsStart())
  } catch (e) {
    // const { setShowResendEmail } = action && action.payload

    // if (e?.response?.data?.errors[0]?.errorCode === 3043) {
    //   if (e?.response?.data?.errors[0]?.name === 'Email not verified') {
    //     setShowResendEmail(true)
    //   } else {
    //     setShowResendEmail(false)
    //   }
    //   // INCORRECT_CREDENTIAL
    //   yield toast(e?.response?.data?.errors[0]?.description, 'error')
    //   yield put(userLoginFailure(e?.response?.data?.errors[0]?.description))
    // } else
    if (e?.response?.data?.errors[0]?.name === 'INCORRECT_CREDENTIAL') {
      yield toast(`${t('incorrectCredentials')}`, 'error')
    } else if (e?.response?.data?.errors[0]?.name === 'IN_ACTIVE_ENTITY') {
      yield toast(`${t('blacklistedError')}`, 'error')
    } else if (e?.response?.data?.errors[0]?.name === 'TenanatAreBlocked') {
      yield put(resetState())
      yield toast(`${t('tenanatAreBlocked')}`, 'error')
    } else {
      yield toast(e?.response?.data?.errors[0]?.description, 'error')
    }
    yield put(userLoginFailure(e.message))
  }
}
function * checkRefreshTokenWorker () {
  try {
    if (getRefreshToken()) {
      const { data } = yield checkRefreshToken()
      if (data?.data?.success) {
        yield put(resetState())
        yield put(logoutComplete())
      } else {
        const { accessToken, refreshToken } = data?.data
        if (accessToken && refreshToken) {
          yield setLoginToken(accessToken)
          yield setRefreshToken(refreshToken)
          yield put(getUserDetailsStart())
        }
      }
    } else {
      yield put(resetState())
      yield put(logoutComplete())
    }
  } catch (e) {}
}

function * logoutWorker (action) {
  const { handleLogoutModal } = action?.payload
    ? action.payload
    : { handleLogoutModal: false }
  try {
    const res = yield logout()
    if (res.status === 200) {
      yield put(resetState())
      yield toast(t('userLoggedOut'), 'success')
      if (handleLogoutModal) {
        yield handleLogoutModal()
      }
    }
    yield put(logoutComplete())
  } catch (e) {
    if (e?.response?.data?.errors[0]?.name === 'TenanatAreBlocked') {
      yield put(resetState())
      if (handleLogoutModal) {
        yield handleLogoutModal()
      }
      yield toast(`${t('tenanatAreBlocked')}`, 'error')
    }
    // else {
    //   yield toast(e?.response?.data?.errors[0]?.description, 'error')
    // }
    yield put(resetState())
    yield put(logoutComplete())
  }
}

function * getRegistrationFieldsWorker () {
  try {
    const { data } = yield getRegistrationFields()

    yield put(getRegistrationFieldsSuccess(data?.data?.registrationFields))
  } catch (e) {
    yield put(getRegistrationFieldsFailure(e.message))
  }
}

function * forgetPasswordWorker (action) {
  try {
    const email = action && action.payload

    const { data } = yield forgetPassword(email)
    yield put(forgetPasswordComplete(data?.data?.forgetPasswordEmailSent))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(forgetPasswordComplete())
  }
}

function * resetPasswordWorker (action) {
  const { data, navigate } = action && action.payload
  try {
    const encryptedOldPassword = Buffer.from(data.oldPassword).toString(
      'base64'
    )
    const encryptedNewPassword = Buffer.from(data.newPassword).toString(
      'base64'
    )
    const response = yield resetPassword({
      ...data,
      oldPassword: encryptedOldPassword,
      newPassword: encryptedNewPassword
    })

    if (response?.status === 200) {
      yield toast(`${t('updatedSuccess')}`, 'success')
      yield navigate(Routes.homepage)
      yield removeLoginToken()
    }
    yield put(resetPasswordComplete())
  } catch (e) {
    yield put(resetPasswordComplete())
    if (e?.response?.data?.errors[0]?.name === 'OldPasswordDidNotMatch') {
      yield toast(`${t('oldPasswordDidNotMatch')}`, 'error')
    } else {
      yield toast(e?.response?.data?.errors[0]?.description, 'error')
    }
  }
}

function * userSignUpWorker (action) {
  try {
    const { initialState, navigate, closeModal } = action && action.payload

    const encryptedPass = Buffer.from(initialState.password).toString('base64')
    const encryptedWithDrawPassWord = Buffer.from(
      initialState.withdrawalPassword
    ).toString('base64')

    const encryptedconfirmPassword = Buffer.from(initialState.confirmPassword).toString('base64')
    const encryptedconfirmPasswordWithdrawal = Buffer.from(initialState.confirmPasswordWithdrawal).toString('base64')

    yield userSignUp({
      ...initialState,
      password: encryptedPass,
      withdrawalPassword: encryptedWithDrawPassWord,
      confirmPassword: encryptedconfirmPassword,
      confirmPasswordWithdrawal: encryptedconfirmPasswordWithdrawal
    })
    yield put(userSignUpComplete())
    yield toast(`${t('signupSuccess')}`, 'success')
    yield put(
      userLoginStart({
        ...initialState,
        isSignup: true,
        navigate,
        closeModal,
        isSignUp: true
      })
    )
  } catch (e) {
    if (e?.response?.data?.errors[0]?.name === 'UserNameExists') {
      yield toast(`${t('userNameAlreadyExists')}`, 'error')
    } else if (
      e?.response?.data?.errors[0]?.name === 'UserCountryCodeWithPhone'
    ) {
      yield toast(`${t('UserCountryCodeWithPhoneAlreadyExists')}`, 'error')
    } else if (e?.response?.data?.errors[0]?.name === 'InvalidReferralCode') {
      yield toast(`${t('referralCodeInvalid')}`, 'error')
    } else if (e?.response?.data?.errors[0]?.name === 'TenanatAreBlocked') {
      yield put(resetState())
      yield toast(`${t('tenanatAreBlocked')}`, 'error')
    } else {
      yield toast(e?.response?.data?.errors[0]?.description, 'error')
    }
    yield put(userSignUpComplete())
  }
}

function * getTenantDetailsWorker () {
  try {
    const { data } = yield getTenantDetails()

    yield put(getTenantDetailsSuccess(data?.data?.tenantDetail))
  } catch (e) {
    yield put(
      getTenantDetailsFailure(e?.response?.data?.errors[0]?.description)
    )
  }
}

function * getAllCountriesWorker () {
  try {
    const { data } = yield getAllCountries()

    yield put(getAllCountriesSuccess(data?.data?.countries))
  } catch (e) {
    yield put(
      getAllCountriesFailure(e?.response?.data?.errors[0]?.description)
    )
  }
}

function * verifyEmailWorker (action) {
  const { navigate } = action.payload

  try {
    const { emailToken } = action.payload

    yield verifyEmail({ emailToken: emailToken.substring(12) })
    yield put(verifyEmailComplete(true))
  } catch (e) {
    if (e?.response?.status === 422) {
      yield toast(t('linkExpired'), 'error')
      yield put(verifyEmailComplete(false))
    } else if (e?.response?.data?.errors[0]?.errorCode === 3009) {
      const languageData = yield select(getLanguageData)
      yield toast(e?.response?.data?.errors[0]?.description, 'Error')
      yield put(resendEmailComplete())
      navigate(
        `/${
          getItem('language')?.toLowerCase() ||
          languageData?.language?.toLowerCase() ||
          'en'
        }/login`
      )
    } else {
      yield toast(e?.response?.data?.errors[0]?.description, 'error')
      yield put(verifyEmailComplete(false))
    }
  }
}

function * resendEmailWorker (action) {
  const { navigate, isProfilePage } = action.payload

  try {
    const { email } = action.payload

    yield resendEmail(email)
    yield put(resendEmailComplete())
    yield toast(t('linkSent'), 'success')
    if (!isProfilePage) navigate('/verify-email')
  } catch (e) {
    if (e?.response?.data?.errors[0]?.errorCode === 3044) {
      yield toast(t('accAlreadyVerified'), 'success')
      yield put(resendEmailComplete())
      const languageData = yield select(getLanguageData)
      if (!isProfilePage) {
        navigate(
          `/${
            getItem('language')?.toLowerCase() ||
            languageData?.language?.toLowerCase()
          }/login`
        )
      }
    } else {
      yield toast(e?.response?.data?.errors[0]?.description, 'error')
      yield put(resendEmailComplete())
    }
  }
}

function * getThemesWorker () {
  try {
    const { data } = yield getThemes()
    yield put(
      getThemesSuccess(data?.data?.ThemeList?.map(({ theme }) => theme))
    )
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getThemesFailure())
  }
}
