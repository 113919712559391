import { getRequest, postRequest } from './axios'

const { REACT_APP_API_URL } = process.env

const getSportsGamesCategory = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/login`)

const getSports = (data) =>
  getRequest(`${REACT_APP_API_URL}/api/sportsbook/getSports`, data)

const getLeagues = ({ sportId, countryId, page, limit }) =>
  getRequest(`${REACT_APP_API_URL}/api/sportsbook/getLeagues?sportId=${sportId}&countryId=${countryId}&page=${page}&limit=${limit}`)

// const getEvents = ({ isLive, page, limit, language, sportId, userId, countryId, searchParam, languageCode, masterTournamentId }) =>
//   getRequest(`${REACT_APP_API_URL}/api/sportsbook/eventList?isLive=${isLive}&page=${page}&limit=${limit}&language=${language || ''}&sportId=${sportId}&userId=${userId || ''}&countryId=${countryId || ''}&searchParam=${searchParam || ''}&tournamentId=${masterTournamentId || ''}`)
const getEvents = (data) =>
  getRequest(`${REACT_APP_API_URL}/api/sportsbook/eventList`, data)

const getMarketCategories = () =>
  getRequest(`${REACT_APP_API_URL}/api/sportsbook/marketCategories`)

const getTopLeagues = ({ page, limit, sportId }) =>
  getRequest(`${REACT_APP_API_URL}/api/sportsbook/getTopLeagues?page=${page}&limit=${limit}&sportId=${sportId || ''}`)

const getCountries = ({ sportId }) =>
  getRequest(`${REACT_APP_API_URL}/api/sportsbook/getCountries?sportId=${sportId}&pageSize=200&page=1`)

const getMarkets = ({ matchId, page, limit, isLive }) =>
  getRequest(`${REACT_APP_API_URL}/api/sportsbook/eventMarkets?matchId=${matchId}&page=${page}&limit=${limit}&isLive=${isLive}`)

const getTopEvents = (data) =>
  getRequest(`${REACT_APP_API_URL}/api/sportsbook/getTopEvents`, data)

const getResults = ({ page, limit, sportId, tournamentId }) =>
  getRequest(`${REACT_APP_API_URL}/api/sportsbook/getResults?page=${page}&limit=${limit}&sportId=${sportId}&tournamentId=${tournamentId}`)

const getAllBets = ({ limit, page, fromDate, toDate, betSlipStatus }) =>
  getRequest(`${REACT_APP_API_URL}/api/sportsbook/betSlipsList?limit=${limit}&page=${page}&fromDate=${fromDate}&toDate=${toDate}&betSlipStatus=${betSlipStatus}`)

const getSingleEvent = ({ matchId, isLive }) =>
  getRequest(`${REACT_APP_API_URL}/api/sportsbook/singleEvent?matchId=${matchId}&isLive=${isLive}`)

const getBetDetails = ({ betslipId }) =>
  getRequest(`${REACT_APP_API_URL}/api/sportsbook/betList?betslipId=${betslipId}`)

const getCashoutAmount = ({ betslipId }) =>
  getRequest(`${REACT_APP_API_URL}/api/sportsbook/getCashoutAmount?betslipId=${betslipId}`)

const placeBet = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/sportsbook/betPlace`, data)

const processCashout = ({ betslipId, tenantId, cashOutAmount }) =>
  postRequest(`${REACT_APP_API_URL}/api/sportsbook/cashOut?betslipId=${betslipId}&tenantId=${tenantId}&cashOutAmount=${cashOutAmount}`)

const addFavorites = ({ matchId, feedProvider }) =>
  getRequest(`${REACT_APP_API_URL}/api/sportsbook/addToFavorite?matchId=${matchId}&feedProvider=${feedProvider}`)

const getFavorites = ({ isLive, page, limit, sportId, feedProvider }) =>
  getRequest(`${REACT_APP_API_URL}/api/sportsbook/getFavoriteEvents?isLive=${isLive}&page=${page}&limit=${limit}&sportId=${sportId}&feedProvider=${feedProvider}`)

const alternativeStake = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/sportsbook/alternative-stake`, data)

const betReoffer = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/sportsbook/reoffer`, data)

const getCustomEvents = (data) =>
  getRequest(`${REACT_APP_API_URL}/api/sportsbook/available-markets`, data)

const calculateOds = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/sportsbook/calculate-odds`, data)

const getHotCombos = (data) =>
  getRequest(`${REACT_APP_API_URL}/api/sportsbook/hotComboList`, data)

export { getHotCombos, calculateOds, getCustomEvents, betReoffer, alternativeStake, getSportsGamesCategory, getSports, getLeagues, getEvents, getMarketCategories, getTopLeagues, getTopEvents, getResults, getSingleEvent, placeBet, addFavorites, getFavorites, getCountries, getMarkets, getAllBets, getBetDetails, getCashoutAmount, processCashout }
